import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import ImageSlide from "../../components/ImageSlide"

const Container = () => (
  <Layout>
    <SEO title="Home" />
    <div className="home">
      <header
        className="color-bg light-typo padding-top-bottom bg-image detail-header"
        style={{
          backgroundImage:
            "url(/images/spaces/facility/barbecue/facility_barbecue_02.jpg)",
          backgroundPosition: "center",
        }}
      >
        <div className="container">
          <h1 className="page-title">바베큐장</h1>

          <p className="page-subtitle">
            여름엔 비, 모기 걱정 없이, 겨울엔 따뜻하게 바베큐를 해 드실 수
            있어요.{" "}
          </p>
        </div>
      </header>

      <article className="post single-post">
        <div className="container scrollimation fade-up">
          <div className="row">
            <div className="col-sm-10 col-sm-offset-1">
              <div className="post-media">
                <ImageSlide
                  imageList={[
                    <li>
                      <img
                        className="img-responsive"
                        src="/images/spaces/facility/barbecue/facility_barbecue_01.jpg"
                        alt=""
                      />
                      <p className="flex-caption text-center">바베큐장 입구</p>
                    </li>,
                    <li>
                      <img
                        className="img-responsive"
                        src="/images/spaces/facility/barbecue/facility_barbecue_02.jpg"
                        alt=""
                      />
                      <p className="flex-caption text-center">바베큐장 내부</p>
                    </li>,
                    <li>
                      <img
                        className="img-responsive"
                        src="/images/spaces/facility/barbecue/facility_barbecue_03.jpg"
                        alt=""
                      />
                      <p className="flex-caption text-center">바베큐장 내부</p>
                    </li>,
                    <li>
                      <img
                        className="img-responsive"
                        src="/images/spaces/facility/barbecue/facility_barbecue_04.jpg"
                        alt=""
                      />
                      <p className="flex-caption text-center">바베큐장 내부</p>
                    </li>,
                    <li>
                      <img
                        className="img-responsive"
                        src="/images/spaces/facility/barbecue/facility_barbecue_05.jpg"
                        alt=""
                      />
                      <p className="flex-caption text-center">
                        바베큐장 테이블
                      </p>
                    </li>,
                    <li>
                      <img
                        className="img-responsive"
                        src="/images/spaces/facility/barbecue/facility_barbecue_06.jpg"
                        alt=""
                      />
                      <p className="flex-caption text-center">
                        식기 및 조리공간
                      </p>
                    </li>,
                    <li>
                      <img
                        className="img-responsive"
                        src="/images/spaces/facility/barbecue/facility_barbecue_07.jpg"
                        alt=""
                      />
                      <p className="flex-caption text-center">난로</p>
                    </li>,
                  ]}
                />
              </div>

              <div className="post-content">
                <div>
                  <h2 className="text-center">바베큐장 이용 안내</h2>
                  <p>
                    <b>사용 요금</b>: 4인기준 10,000원
                  </p>
                  <p>
                    <b>제공 서비스</b>: 숯불 피워드립니다 (숯, 불판 제공)
                  </p>
                  <p>
                    <b>유의사항</b>:
                    <br />
                    - 바베큐장 내부에도 그릇, 가위, 수저 등 바베큐에 필요한
                    식기들이 준비되어 있으니 객실에서 가져오지 않으셔도 됩니다.
                    <br />- 불판을 제외한 설거지는 해주셔야 합니다.
                  </p>
                </div>
              </div>

              <div className="text-center">
                <a className="btn btn-qubico" href="/reservation/reserve">
                  <i className="fa fa-shopping-cart"></i>예약하기
                </a>
              </div>
            </div>
          </div>
        </div>
      </article>
    </div>
  </Layout>
)

export default Container
